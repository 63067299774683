import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { registrationFieldsByLocale } from '@common/constants'
import HttpStatusCode from '@common/httpStatusCodes'
import regexp from '@common/regexp'
import terms, { locale } from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { dateInRange, normalizeYearTo4Characters } from '@lib/validators'
import { UserRegistrationData, RegistrationDataAll } from '@models'
import { createToast } from '@redux/action-types'
import api, { ResponseOk, SignUpResponse } from '@services/api'
import { firstLetterUpperCase } from '@utils/letters'

const getCaptcha = (setValueField, setState) =>
  api.client.getCaptcha().then((res) => {
    setValueField('key', res.key)
    setState(res.img)
  })

const useSignUpForm = () => {
  const [loading, setLoading] = useState(false)
  const [captchaImage, setCaptchaImage] = useState<string>('')
  const { register, handleSubmit, setError, setValue, watch, ...rest } =
    useForm<RegistrationDataAll>({ mode: 'all' })

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const noNationality = Boolean(watch('noNationality'))
  useEffect(() => {
    getCaptcha(setValue, setCaptchaImage)
  }, [])

  const localeNamePattern = {
    value:
      locale === 'ru'
        ? regexp.ruLettersWithSpecialChars
        : regexp.enLettersWithSpecialChars,
    message:
      locale === 'ru' ? terms.ONLY_LOCALE_IS_ALLOWED : terms.LATIN_CHARACTERS_ONLY,
  }

  const fields = {
    code: register('code', {
      required: terms.REQUIRED_FIELD,
    }),
    surname: register(registrationFieldsByLocale.surname, {
      required: terms.REQUIRED_FIELD,
      pattern: localeNamePattern,
      onChange: (e) => (e.target.value = firstLetterUpperCase(e.target.value)),
    }),
    name: register(registrationFieldsByLocale.name, {
      required: terms.REQUIRED_FIELD,
      pattern: localeNamePattern,
      onChange: (e) => (e.target.value = firstLetterUpperCase(e.target.value)),
    }),
    patronymic: register(registrationFieldsByLocale.patronymic, {
      pattern: localeNamePattern,
      onChange: (e) => (e.target.value = firstLetterUpperCase(e.target.value)),
    }),
    birthday: register('date_birthday', {
      required: terms.REQUIRED_FIELD,
      validate: (value) => dateInRange(value, 10, 100) || terms.DATE_RANGE,
      onChange: ({ target: { value } }) =>
        value && setValue('date_birthday', normalizeYearTo4Characters(value)),
    }),
    email: register('email', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.email,
        message: terms.VALID_EMAIL,
      },
    }),
    password: register('password', {
      required: 'Обязательное поле',
      pattern: {
        value: regexp.password,
        message: terms.PASSWORD_MIXED,
      },
      minLength: {
        value: 6,
        message: terms.PASSWORD_MIN_LENGTH,
      },
    }),
    accept: register('accept_fz', {
      required: terms.REQUIRED_FIELD,
    }),
    nationality: register('nationality', { required: terms.REQUIRED_FIELD }),
    captcha: register('captcha', { required: terms.REQUIRED_FIELD }),
    key: register('key'),
    noNationality: register('noNationality', {
      onChange: (e) => {
        const { checked } = e.target
        if (checked) {
          setValue('nationality', 0)
          setValue('noNationality', true)
        }
      },
    }),

    /** программы среднего профессионального образования */
    programsSpo: register('programs.spo'),
    /** программы баклавриата и специалитета */
    programsBakAndSpec: register('programs.bakAndSpec'),
    /** программы магистратуры */
    programsMag: register('programs.mag'),
    /** программы аспирантуры */
    programsAsp: register('programs.asp'),
    /** программы ассистентуры-стажировки */
    programsIntern: register('programs.intern'),

    noName: register('noName', {
      onChange: (e) => {
        const { checked } = e.target
        if (checked) {
          setValue('name_ru', '-')
          setValue('name_en', '-')
        }
      },
    }),
    noSurname: register('noSurname', {
      onChange: (e) => {
        const { checked } = e.target
        if (checked) {
          setValue('surname_ru', '-')
          setValue('surname_en', '-')
        }
      },
    }),
  }
  const onSubmit = handleSubmit((userData: any) => {
    let success: boolean = false
    setLoading(true)

    let url
    switch (userData.code) {
      case '1':
        url = '/rus-sign-in'
        break
      case '2':
        url = '/ino-sign-in'
        break
      case '3':
        url = '/asp-sign-in'
        break
      default:
        url = '/rus-sign-in'
        break
    }

    if (process.env.USE_ANALYTICS) {
      //@ts-ignore
      ym(70526236, 'reachGoal', 'application_register_send')
    }

    const { noName, noSurname, nationality, ...rest } = userData

    api.client
      .signup({
        ...rest,
        nationality: nationality.value,
        no_name: noName,
        no_surname: noSurname,
      } as RegistrationDataAll)
      .then((response: SignUpResponse) => {
        if (process.env.USE_ANALYTICS) {
          //@ts-ignore
          ym(70526236, 'reachGoal', 'application_register_success')
        }
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
        success = true
      })
      .catch((response: Exclude<SignUpResponse, ResponseOk>) => {
        getCaptcha(setValue, setCaptchaImage)
        const { status } = response

        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))

          Object.keys(data.errors).forEach((key) => {
            setError(key as keyof RegistrationDataAll, {
              type: 'manual',
              message: data.errors[key][0],
            })
          })
        } else if (status === HttpStatusCode.FORBIDDEN) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
      .finally(() => {
        if (success) {
          return navigate(url)
        }
        setLoading(false)
      })
  })

  return {
    fields,
    setValue,
    captchaImage,
    onSubmit,
    loading,
    noNationality,
    watch,
    ...rest,
  }
}

export default useSignUpForm
