import { useMemo, useState } from 'react'
import { UseFormWatch } from 'react-hook-form'

import { RegistrationDataAll } from './../models/auth'

type Props = {
  onSubmit: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>
  watch: UseFormWatch<RegistrationDataAll>
}

const useRegisterCheckbox = (props: Props) => {
  const { watch, onSubmit } = props

  const [isShowCheckboxesError, setIsShowCheckboxesError] = useState(false)
  const [isShowRegisterError, setIsShowRegisterError] = useState(false)

  const isRussia = useMemo(() => {
    const nationality = watch('nationality') as any
    const isRussiaNationalityLabel = 'РОССИЯ'

    return !!(
      nationality &&
      nationality.label &&
      nationality.label === isRussiaNationalityLabel
    )
  }, [watch('nationality')])

  const bakAndSpec = Boolean(watch('programs.bakAndSpec'))
  const spo = Boolean(watch('programs.spo'))
  const asp = Boolean(watch('programs.asp'))
  const mag = Boolean(watch('programs.mag'))
  const intern = Boolean(watch('programs.intern'))

  const isDisabledSubmit = useMemo(() => {
    if (!isRussia) {
      return false
    }

    const noEducation = !bakAndSpec && !spo && !asp && !mag && !intern

    if (noEducation) {
      return true
    }

    const noGeneralDegrees = !spo && !asp && !intern
    const exclusiveGeneralDegree = (bakAndSpec && !mag) || (mag && !bakAndSpec)

    return noGeneralDegrees && exclusiveGeneralDegree
  }, [bakAndSpec, spo, asp, mag, intern, isRussia])

  const isShowInfoMessage = useMemo(() => {
    if (!isRussia) {
      return false
    }

    if (spo || asp) {
      return true
    }

    return false
  }, [isRussia, spo, asp])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (isRussia && !bakAndSpec && !spo && !asp && !mag && !intern) {
      setIsShowCheckboxesError(true)
      return
    }

    if (isDisabledSubmit) {
      setIsShowRegisterError(true)
      return
    }

    await onSubmit(e)
  }

  return {
    isRussia,
    isShowRegisterError,
    isShowCheckboxesError,
    isShowInfoMessage,
    setIsShowCheckboxesError,
    handleSubmit,
  }
}

export default useRegisterCheckbox
