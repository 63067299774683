import { NavRoute } from 'types/custom-types'

import { ModeratorStatementsSectionKey } from '@models'

export const moderatorStatementsRoutes: NavRoute<ModeratorStatementsSectionKey>[] = [
  {
    to: 'basic-data',
    key: 'basicData',
    title: 'Основные данные',
    text: '',
    status: 'not visited',
  },
  {
    to: 'passport-data',
    key: 'passportData',
    title: 'Паспортные данные',
    text: '',
    status: 'not visited',
  },
  {
    to: 'registration-address',
    key: 'realAddress',
    title: 'Адрес регистрации (по паспорту) и адрес проживания в настоящий момент',
    text: '',
    status: 'not visited',
  },
  {
    to: 'compatriot-data',
    key: 'compatriotData',
    title: 'Документы подтверждающие статус соотечественника',
    hidden: true,
    text: '',
    status: 'not visited',
  },
  {
    to: 'educational-background',
    key: 'educationalBackground',
    title: 'Сведения об образовании',
    text: '',
    status: 'not visited',
  },
  {
    to: 'achievements',
    key: 'achievements',
    title: 'Достижения',
    text: '',
    status: 'not visited',
  },
  {
    to: 'privileges',
    key: 'privileges',
    title: 'Льготы',
    text: '',
    status: 'not visited',
  },
  {
    to: 'exam-results',
    key: 'examResults',
    title: 'Результаты ЦТ/ЦЭ /Проверить результаты ЕГЭ',
    text: '',
    status: 'not visited',
  },
  {
    to: 'test',
    title: 'Вступительные испытания',
    text: '',
    key: 'test',
    status: 'not visited',
  },
  {
    to: 'directions',
    key: 'directions',
    title: 'Направления поступления',
    text: '',
    status: 'not visited',
  },
  {
    to: 'target-contract',
    key: 'targetContract',
    title:
      'Информация об одобренной заявке целевого обучения на портале «Работа России»',
    text: '',
    status: 'not visited',
  },
  {
    to: 'additional-info',
    title: 'Дополнительные сведения',
    text: '',
    key: 'additionalInfo',
    status: 'not visited',
  },
  {
    to: 'additional-forms',
    key: 'additionalForms',
    title: 'Дополнительные файлы',
    text: '',
    status: 'not visited',
  },
  {
    to: 'downloads',
    key: 'downloads',
    title: 'Файлы для скачивания',
    text: '',
    status: 'not visited',
  },
  {
    to: 'visa',
    key: 'visa',
    title: 'Виза',
    text: '',
    status: 'not visited',
  },
  {
    to: 'legal-representative',
    key: 'legal-representative',
    title: 'Законный представитель',
    text: '',
    status: 'not visited',
  },
  {
    to: 'certificate',
    title: 'Аттестат',
    text: '',
    key: 'certificate',
    status: 'not visited',
  },
  {
    to: 'directionTrainings',
    title: 'Согласие на зачисление',
    text: '',
    key: 'directionTrainings',
    status: 'not visited',
  },
  {
    to: 'conclusion-base-department',
    title: 'Заключение базовой кафедры',
    text: '',
    key: 'conclusionBaseDepartment',
    status: 'not visited',
  },
]
