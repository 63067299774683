export default {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g,
  enWhitoutQuotationMarks: /^[a-zA-Z.`' \-]+$/g,
  ru: /^[А-ЯЁ][а-яё\-\.\s]*$/g,
  allLetterRu: /^(?:[А-ЯЁ][а-яё]*[\s\-]*)+$/,
  ruLettersWithSpecialChars: /^(?:-|[А-ЯЁ][а-яё]*(?:(?:[\s-]+)[А-ЯЁа-яё][а-яё]*)*)$/,
  ruAndSymbol: /^[А-Яа-яЁё\-\s]*$/g,
  ruAndSymbols: /^[А-Яа-яЁё0-9/№()"\\.,\-\s]*$/g,
  ruSymbolAndNumber: /^[0-9А-Яа-яЁё/№()"\-\s]*$/g,
  ruSymbolAndNumberAndComa: /^[0-9А-Яа-яЁё.,\-\s]*$/g,
  en: /^[a-zA-Z.`'\-\.\s]*$/g,
  allLetterEn: /^(?:[A-Z][a-zA-Z]*[\s\-\.]*)+$/g,
  enLettersWithSpecialChars:
    /^(?:-|[A-Z][a-zA-Z]*(?:(?:[\s\-\.]+)[A-Za-z][a-zA-Z]*)*)$/,
  allLetterEnWithDash: /(^(?:[A-Z][a-zA-Z]*[\s\-\.]*)+$)|(^\-{1}$)/g,
  ruWhitoutQuotationMarks: /^[А-Яа-яЁё.' \-]+$/g,
  nameExtended: /^[a-zA-ZА-Яа-яЁё.,;`'\\_#%"<>!? \-]+$/g,
  lettersAndSymbols: /^[0-9a-zA-ZА-Яа-яЁё,._/\\#%"<>!? \-]+$/g,
  lettersAndSymbolsExtended:
    /^[a-zA-ZА-Яа-яЁё0-9,;.|\r\n:_#%"<>!?+/()№[\]=\{\}\&\|\*\\ \-]+$/g,
  year: /^\d{4}$/g,
  mobile: /^([\+]?[0-9() \-]+)$/g,
  passportSeries: /^([0-9]{4})$/g,
  passportRussianForeignSeries: /^([0-9]{2})$/g,
  numberInList: /^([0-9]{3})$/g,
  passportSeriesForeign: /^([a-zA-Z0-9])/g,
  passportSeriesForeignSymbols: /^([а-яА-ЯёЁa-zA-Z0-9\s]*$)/g,
  divisionCode: /^([0-9]){3}-([0-9]){3}$/g,
  passportNumber: /^([0-9]{6})$/g,
  passportRussianForeignNumber: /^([0-9]{7})$/g,
  passportNumberForeign: /^([0-9])/g,
  date: /^\d{2}.\d{2}.\d{4}$/g,
  dateOnlyPointFormat: /^\d{2}\.\d{2}\.\d{4}$/g,
  dateOnlyYear: /^\d{4}$/g,
  site: /^((https?):\/\/)(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/g,
  fromOne: /^[1-9][0-9]*$/g,
  positiveNumbers: /^\d*[1-9]\d*$/g,
  textArea: /^[a-zA-ZА-Яа-яЁё0-9,;.|\r\n:_#%"<>!?+/()№[\]=\{\}\&\|\*\\ \-]*$/g,
  password: /^(?=.*[$&+,:;=?@#|'<>.^*_()%!-])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
  bankIdentifierCode: /^([0-9]{9})$/g,
  checkingAndCorrespondentAccounts: /^([0-9]{20})$/g,
  checkingNumberAndCyrillicInString: /^[а-яА-ЯёЁ0-9\s]+$/,
  checkingNumberAndCyrillicDashInString: /^[а-яА-ЯёЁ0-9- .]+$/,
  checkingSymbolsNumberAndCyrillicDashInString: /^[а-яА-ЯёЁ0-9-/,() ."«»№:\\]+$/,
  checkingAnotherSymbolsNumberAndCyrillicDashInString: /^[а-яА-ЯёЁ0-9-/()№ .]+$/,
  checkingNumberAndCyrillicAndLatinInString: /^[а-яА-Яa-zA-Z0-9]+$/,
}
