import { useForm } from 'react-hook-form'

import { RUSSIA_NATIONAL_ID, WEB_METHOD_APPLICATION_ID } from '@common/constants'
import regexp from '@common/regexp'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useProfileApiHandling from '@hooks/useProfileApiHandling'
import {
  normalizeYearTo4Characters,
  dateInRange,
  validateSNILS,
  normalizeSNILS,
  getMobileValidators,
  validateDate,
} from '@lib/validators'
import { SelectOption, PersonalData, PersonalFormData } from '@models'
import { getPersonalData } from '@redux/action-types'
import { getViewerProfile } from '@redux/reducers'
import api from '@services/api'
import { firstLetterUpperCase } from '@utils/letters'

const usePersonalDataForm = () => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    getValues,
    watch,
    ...rest
  } = useForm<PersonalData>({ mode: 'all' })

  const { callApi, loading } = useProfileApiHandling({
    setError,
    apiFn: api.client.updatePersonalData,
    onSuccessCb: () => {
      dispatch(getPersonalData())
      dispatch(getViewerProfile())
    },
  })
  let noNationality = Boolean(watch('noNationality'))
  const dispatch = useAppDispatch()
  const national = watch('nationality') as unknown as SelectOption | undefined
  const phoneCode = watch('phoneCode') as unknown as
    | (SelectOption & { chipLabel: string })
    | undefined
  const additionalPhoneCode = watch('additionalPhoneCode') as unknown as
    | (SelectOption & { chipLabel: string })
    | undefined
  const partnerOrganizationCode = watch('partnerOrganizationCode') as unknown as
    | (SelectOption & { chipLabel: string })
    | undefined
  const isRussiaCitizenship = national?.value === RUSSIA_NATIONAL_ID
  const snilsRequired = !watch('noSnils')
  const snilsDateRequired = !watch('noDateSnils')
  const snils = watch('snils')
  const inRussia = watch('inRussia') === true
  const isAdult =
    new Date().getFullYear() - new Date(watch('dateBirthday')).getFullYear() >= 18

  const fields = {
    nameRu: register('nameRu', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.ruLettersWithSpecialChars,
        message: terms.CYRILLIC_CHARACTERS_ONLY,
      },
      onChange: (e) => (e.target.value = firstLetterUpperCase(e.target.value)),
    }),
    surnameRu: register('surnameRu', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.ruLettersWithSpecialChars,
        message: terms.CYRILLIC_CHARACTERS_ONLY,
      },
      onChange: (e) => (e.target.value = firstLetterUpperCase(e.target.value)),
    }),
    patronymicRu: register('patronymicRu', {
      required: { value: !watch('noPatronymic'), message: terms.REQUIRED_FIELD },
      pattern: {
        value: regexp.ruLettersWithSpecialChars,
        message: terms.CYRILLIC_CHARACTERS_ONLY,
      },
      onChange: (e) => (e.target.value = firstLetterUpperCase(e.target.value)),
    }),
    nameEn: register('nameEn', {
      required: { value: !isRussiaCitizenship, message: terms.REQUIRED_FIELD },
      pattern: {
        value: regexp.enLettersWithSpecialChars,
        message: terms.LATIN_CHARACTERS_ONLY,
      },
      onChange: (e) => (e.target.value = firstLetterUpperCase(e.target.value)),
    }),
    surnameEn: register('surnameEn', {
      required: { value: !isRussiaCitizenship, message: terms.REQUIRED_FIELD },
      pattern: {
        value: regexp.enLettersWithSpecialChars,
        message: terms.LATIN_CHARACTERS_ONLY,
      },
      onChange: (e) => (e.target.value = firstLetterUpperCase(e.target.value)),
    }),
    patronymicEn: register('patronymicEn', {
      pattern: {
        value: regexp.enLettersWithSpecialChars,
        message: terms.LATIN_CHARACTERS_ONLY,
      },
      onChange: (e) => (e.target.value = firstLetterUpperCase(e.target.value)),
    }),
    nationality: register('nationality', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        if (value?.value === RUSSIA_NATIONAL_ID) {
          setValue('noSnils', false)
        } else {
          setValue('noSnils', true)
          setValue('snils', null)
          setValue('dateSnils', null)
          setValue('noDateSnils', false)
          clearErrors('noDateSnils')
          clearErrors('snils')
          clearErrors('dateSnils')
        }
      },
    }),
    dateBirthday: register('dateBirthday', {
      required: terms.REQUIRED_FIELD,
      validate: (value) => dateInRange(value, 10, 100) || terms.DATE_RANGE,
      onChange: ({ target: { value } }) =>
        value && setValue('dateBirthday', normalizeYearTo4Characters(value)),
    }),
    gender: register('gender', {
      required: terms.REQUIRED_FIELD,
      onChange: () => {
        setValue('maritalStatusId', null)
      },
    }),
    workId: register('workId'),
    amountChild: register('amountChild'),
    maritalStatusId: register('maritalStatusId'),
    phoneCode: register('phoneCode', {
      required: {
        value: isRussiaCitizenship,
        message: terms.REQUIRED_FIELD,
      },
      onChange: ({ target: { value } }) => value && clearErrors('mobile'),
    }),
    additionalPhoneCode: register('additionalPhoneCode', {
      //  required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => value && clearErrors('additionalMobile'),
    }),
    mobile: register('mobile', {
      required: terms.REQUIRED_FIELD,
      ...getMobileValidators(phoneCode?.chipLabel),
    }),
    additionalMobile: register('additionalMobile', {
      //  required: terms.REQUIRED_FIELD,
      ...getMobileValidators(additionalPhoneCode?.chipLabel),
    }),
    dopEmail: register('dopEmail', {
      pattern: { value: regexp.email, message: terms.VALID_EMAIL },
    }),
    snils: register('snils', {
      disabled: watch('noSnils'),
      required: { value: snilsRequired, message: terms.REQUIRED_FIELD },
      validate: (value) =>
        value && value.length >= 14
          ? validateSNILS(normalizeSNILS(value))
            ? true
            : terms.SNILS_INVALID
          : true,
      onChange: ({ target: { value } }) =>
        value && setValue('snils', normalizeSNILS(value)),
    }),
    noDateSnils: register('noDateSnils', {
      onChange: ({ target: { checked } }) => {
        if (checked) {
          setValue('dateSnils', null)
          clearErrors('dateSnils')
        }
      },
    }),
    inRussia: register('inRussia', {
      onChange: (event) => {
        const target = event.target
        if (target.value === '1') {
          setValue('inRussia', true)
        } else {
          setValue('inRussia', false)
        }
      },
    }),
    dateSnils: register('dateSnils', {
      disabled: watch('noSnils') || watch('noDateSnils'),
      required: {
        value: snilsRequired || snilsDateRequired,
        message: terms.REQUIRED_FIELD,
      },
      onChange: ({ target: { value } }) =>
        value && setValue('dateSnils', normalizeYearTo4Characters(value)),
      validate: (value) =>
        validateDate(value, getValues('dateBirthday')) ? true : terms.DATE_INVALID,
    }),
    noName: register('noName', {
      onChange: (e) => {
        const { checked } = e.target
        if (checked) {
          setValue('nameRu', '-')
          setValue('nameEn', '-')
        }
      },
    }),
    noSurname: register('noSurname', {
      onChange: (e) => {
        const { checked } = e.target
        if (checked) {
          setValue('surnameRu', '-')
          setValue('surnameEn', '-')
        }
      },
    }),
    noPatronymic: register('noPatronymic', {
      onChange: (e) => {
        const { checked } = e.target
        if (checked) {
          clearErrors('patronymicRu')
          clearErrors('patronymicEn')
          setValue('patronymicRu', '')
          setValue('patronymicEn', '')
        }
      },
    }),
    noNationality: register('noNationality', {
      onChange: (e) => {
        const { checked } = e.target
        if (checked) {
          setValue('nationality', 0)
          setValue('noNationality', true)
        }
      },
    }),
    hasPhoto: register('hasPhoto', { required: terms.REQUIRED_FIELD }),
    noSnils: register('noSnils', {
      onChange: ({ target: { checked } }) => {
        if (checked) {
          setValue('snils', null)
          setValue('dateSnils', null)
          setValue('noDateSnils', false)
          clearErrors('noDateSnils')
          clearErrors('snils')
          clearErrors('dateSnils')
        }
      },
    }),
    recruitingCompany: register('recruitingCompany'),
    partnerOrganizationEmail: register('partnerOrganizationEmail', {
      pattern: { value: regexp.email, message: terms.VALID_EMAIL },
    }),
    partnerOrganizationCode: register('partnerOrganizationCode'),
    partnerOrganizationPhone: register('partnerOrganizationPhone', {
      ...getMobileValidators(partnerOrganizationCode?.chipLabel),
    }),
  }

  const onSubmit = handleSubmit((data: any) => {
    if (
      !getValues('noSnils') &&
      !validateSNILS(getValues('snils') || '') &&
      !validateDate(getValues('dateSnils') || '', getValues('dateBirthday'))
    ) {
      setError('snils', { type: 'validate', message: terms.SNILS_INVALID })
      setError('dateSnils', { type: 'validate', message: terms.DATE_INVALID })
      return
    }

    const normalizedData: PersonalData = {
      ...data,
      gender: data.gender.value,
      dopEmail: data.dopEmail || null,

      phoneCode: data.phoneCode?.chipLabel,
      mobile:
        (data.phoneCode?.chipLabel ? `${data.phoneCode?.chipLabel} ` : '') +
        data.mobile.replace(/\s/g, ''),

      nationality: data.nationality.value,

      additionalPhoneCode: data.additionalMobile
        ? data.additionalPhoneCode?.chipLabel
        : null,

      additionalMobile: data.additionalMobile
        ? (data.additionalPhoneCode?.chipLabel
            ? `${data.additionalPhoneCode?.chipLabel} `
            : '') + data.additionalMobile.replace(/\s/g, '')
        : null,

      methodApplicationId: WEB_METHOD_APPLICATION_ID,
      workId: data.workId?.value,
      maritalStatusId: data.maritalStatusId?.value,
      partnerOrganizationCode: data.partnerOrganizationCode?.chipLabel,
      partnerOrganizationPhone: data.partnerOrganizationPhone
        ? (data.partnerOrganizationCode?.chipLabel
            ? `${data.partnerOrganizationCode?.chipLabel} `
            : '') + data.partnerOrganizationPhone.replace(/\s/g, '')
        : null,
    }

    callApi(normalizedData)
  })

  let codeMainPhone: any = watch('phoneCode')

  let maskMainPhone: string = ''

  if (codeMainPhone != undefined) {
    if (codeMainPhone.chipLabel == '+7') {
      maskMainPhone = '999-999-9999'
    }
  }

  let codePartnerPhone: any = watch('partnerOrganizationCode')

  let maskPartnerPhone: string = ''

  if (codePartnerPhone != undefined) {
    if (codePartnerPhone.chipLabel == '+7') {
      maskPartnerPhone = '999-999-9999'
    }
  }

  let codeAdditionalPhone: any = watch('additionalPhoneCode')

  let maskAdditionalPhone: string = ''

  if (codeAdditionalPhone != undefined) {
    if (codeAdditionalPhone.chipLabel == '+7') {
      maskAdditionalPhone = '999-999-9999'
    }
  }

  return {
    fields,
    watch,
    onSubmit,
    setValue,
    loading,
    getValues,
    clearErrors,
    snils,
    snilsRequired,
    snilsDateRequired,
    isRussiaCitizenship,
    isAdult,
    maskMainPhone,
    maskAdditionalPhone,
    maskPartnerPhone,
    noNationality,
    national,
    inRussia,
    ...rest,
  }
}

export default usePersonalDataForm
