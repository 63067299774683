import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AxiosResponse } from 'axios'
import { NavRoute } from 'types/custom-types'

import { RUSSIA_NATIONAL_ID } from '@common/constants'
import useProfileStatements from '@common/routes/useProfileStatements'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useCitizenshipResult from '@hooks/useCitizenshipResult'
import usePersonalDataForm from '@hooks/usePersonalDataForm'
import {
  findFirstInvalidRoute,
  findNextRoute,
  getFilteredRoutes,
  getRouteStatus,
  validateRoutes,
} from '@lib/routes'
import { ProfileSectionKey } from '@models'
import { createToast } from '@redux/action-types'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'
import api, { CheckProfileStatusOk, CheckProfileStatusResponse } from '@services/api'

type Route = NavRoute<ProfileSectionKey>

const useProvideProfileRoutes = (initialRoutes: Route[] = []) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const currentRoute = useRef<string>()
  const visitedRoutesPaths = useRef<string[]>([])
  const [routes, setRoutes] = useState<Route[]>(initialRoutes)
  const initialRoutesPathnames = useRef(initialRoutes.map((r) => r.to))
  const { APPLICATION_SECTION_ROUTE } = useProfileStatements()

  const [lastBtn, setBtn] = useState<string>('')

  const { visitedRoutes, unvisitedRoutes, visibleRoutes, visitedSortedRoutes } =
    getFilteredRoutes(routes, currentRoute.current)

  const profile = useAppSelector(selectLocalizedViewerProfile)
  let is18 = false
  let today = new Date()
  let ageDifMs =
    today.getTime() -
    new Date('' + profile?.dateBirthday.replace(/-/g, '/') + '').getTime()
  var isAdult
  if (ageDifMs >= 0) {
    let ageDate = new Date(ageDifMs)
    let age = Math.abs(ageDate.getUTCFullYear() - 1970) // Получаем возраст в годах
    isAdult = age >= 18 // Определяем, достиг ли пользователь 18 лет
  }
  if (profile?.dateBirthday) {
    if (!isAdult) {
      is18 = true
    }
  }

  const addressPageIndex = initialRoutes.findIndex((route) => route.to === 'address')

  useEffect(() => {
    setRoutes((prev) =>
      prev.map((route) => {
        if (route.key === 'address') {
          return {
            ...route,
            title: initialRoutes[addressPageIndex].title,
            text: initialRoutes[addressPageIndex].text,
          }
        }
        return { ...route }
      })
    )
  }, [initialRoutes[addressPageIndex].title])

  const hasRussianNationality = useMemo(() => {
    if (!profile || !profile.userNational.length) {
      return false
    }
    const nationalityIds = profile.userNational.map(
      (nationality) => nationality.nationalityId
    )
    return nationalityIds.includes(RUSSIA_NATIONAL_ID)
  }, [profile])

  useEffect(() => {
    if (profile) {
      changeRouteVisibility({
        compatriotDocument: profile?.compatriot,
        legalRepresentative: is18,
        visa: !hasRussianNationality,
        additionalInfo: true,
      })
    }
  }, [profile])

  const onNextPageNavigate = async () => {
    const route = findNextRoute(visibleRoutes, String(currentRoute.current))

    if (route) {
      return navigate(route.to)
    }

    try {
      const response = await api.client.checkProfileStatus()
      const { success } = response.data
      if (success) {
        navigate(APPLICATION_SECTION_ROUTE)
      }
    } catch (response) {
      const { data } = response as AxiosResponse
      const { message } = data as Exclude<
        CheckProfileStatusResponse,
        CheckProfileStatusOk
      >
      if (
        profile?.userNational['0'].nationalityId == RUSSIA_NATIONAL_ID &&
        Object.keys(message).length == 1 &&
        Object.keys(message).shift() == 'legalRepresentative'
      ) {
        navigate(APPLICATION_SECTION_ROUTE)
      } else {
        dispatch(createToast(terms.NOT_ALL_REQUIRED_FIELDS_FILLED, 'danger'))
        const validatedRoutes = validateRoutes<ProfileSectionKey>(
          visibleRoutes,
          Object.keys(message) as ProfileSectionKey[],
          currentRoute.current
        )
        setRoutes(validatedRoutes)
        const route = findFirstInvalidRoute(validatedRoutes)
        route && navigate(route.to)
      }
    }
  }

  const changeRouteVisibility = (params: { [key in Route['key']]?: boolean }) => {
    const keys = Object.keys(params)

    setRoutes((prev) =>
      prev.map((route) => {
        if (keys.includes(route.key)) {
          return { ...route, hidden: !params[route.key] }
        }
        return { ...route }
      })
    )
  }

  const updateRoutesStatuses = (invaidKeys: ProfileSectionKey[]) => {
    setRoutes((prev) =>
      prev.map((route) => ({
        ...route,
        status: getRouteStatus(
          invaidKeys,
          visitedRoutesPaths.current,
          route,
          currentRoute.current
        ),
      }))
    )
  }

  const checkProfileStatus = async () => {
    try {
      const response = await api.client.checkProfileStatus()
      const { success } = response.data
      if (success) {
        updateRoutesStatuses([])
      }
    } catch (response) {
      const { data } = response as AxiosResponse
      const { message, success } = data as Exclude<
        CheckProfileStatusResponse,
        CheckProfileStatusOk
      >

      if (!success) {
        updateRoutesStatuses(Object.keys(message) as ProfileSectionKey[])
      }
    }
  }

  useEffect(() => {
    const { pathname } = location

    let key = pathname.split('/personal-information/')[1]

    if (key === 'educational-background') {
      setBtn(terms.SAVE_AND_CONTINUE)
    } else {
      setBtn('')
    }

    if (!initialRoutesPathnames.current.includes(key)) return

    if (!visitedRoutesPaths.current.includes(key)) {
      visitedRoutesPaths.current.push(key)
    }

    if (key !== currentRoute.current) {
      currentRoute.current = key
      checkProfileStatus()
    }
  }, [location.pathname, checkProfileStatus])

  return {
    lastBtn,
    routes: visibleRoutes,
    visitedRoutes,
    unvisitedRoutes,
    onNextPageNavigate,
    visitedSortedRoutes,
  }
}

export default useProvideProfileRoutes
